import { EngagementJobIdDocument } from '@upper/graphql/freelancer'
import { useQuery } from 'urql'
import JobDetails from './job-details'

type EngagementDetailsProps = {
  engagementId: string | null
  isTalentActive: boolean
  onClose: () => void
}

function EngagementDetails({
  engagementId,
  isTalentActive,
  onClose,
}: EngagementDetailsProps) {
  const [engagementJobIdResult] = useQuery({
    query: EngagementJobIdDocument,
    variables: {
      id: engagementId,
    },
  })

  if (engagementJobIdResult.fetching) {
    return null
  }

  if (engagementJobIdResult.error) {
    return <div>Error: {engagementJobIdResult.error.message}</div>
  }

  return (
    <JobDetails
      jobId={engagementJobIdResult.data?.engagement?.job?.id ?? ''}
      isTalentActive={isTalentActive}
      onClose={onClose}
    />
  )
}

export default EngagementDetails
