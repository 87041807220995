import { TalentKycStatus, TalentStatus } from '@upper/graphql/freelancer'
import {
  TALENT_STATUS_BANNER_TITLE_NO_ACCESS,
  TALENT_STATUS_ICONS,
  TALENT_STATUS_INFOS_NO_ACCESS,
  TALENT_STATUS_LABELS_NO_ACCESS,
  VERIFICATION_STATUS_LABEL,
} from '../lib/talent'
import { ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { useAuth } from '@upper/auth'
import format from 'string-template'
import { HourglassIcon } from '@upper/icons'
import { useTalentStatus } from './with-talent-status'
import { cn } from '@upper/sapphire/ui'

interface Props {
  talentId: string
  title: string | ReactNode
  subtitle?: string | ReactNode
  description?: string
  icon?: ReactNode | JSX.Element
  status?: TalentStatus
  verificationStatus?: TalentKycStatus
  canAttemptVerification?: boolean | null
}

const DynamicIdentityVerificationDialog = dynamic(
  () =>
    import('./indentity-verification').then(
      (mod) => mod.IdentityVerificationDialog
    ),
  {
    loading: () => <p>Loading...</p>,
  }
)

export function DashboardBanner({
  talentId,
  title,
  subtitle,
  description,
  icon,
  status = TalentStatus.Unknown,
  verificationStatus = TalentKycStatus.NotStarted,
  canAttemptVerification = true,
}: Props) {
  const VerifyIcon = TALENT_STATUS_ICONS[status!]

  return (
    <div className="bg-yellow-lightest border-yellow-light relative mb-8 max-w-4xl rounded-md border p-5 md:mb-20 md:min-h-[160px]">
      <img
        src="/images/verify-profile-badge.svg"
        alt=""
        className="-bot-6 absolute -right-6 h-[115px] w-[121px] md:-bottom-10 md:right-5 md:h-[206px] md:w-[195px]"
      />
      <h2 className="relative text-2xl font-semibold leading-tight">{title}</h2>
      <div className="relative mr-52 flex">
        {icon && (
          <div className="flex-shrink-0 p-2">
            {icon ??
              (VerifyIcon && <VerifyIcon className="text-blue h-10 w-10" />)}
          </div>
        )}
        <div className="pt-4">
          {(subtitle ?? TALENT_STATUS_LABELS_NO_ACCESS[status!]) && (
            <h3 className="text-blue font-medium">
              {subtitle ?? TALENT_STATUS_LABELS_NO_ACCESS[status!]}
              {status === TalentStatus.Verification && (
                <span className="text-sm">
                  {' ('}
                  <span
                    className={cn(
                      'text-sm text-black',
                      verificationStatus === TalentKycStatus.Approved &&
                        'text-green-dark',
                      verificationStatus === TalentKycStatus.Declined &&
                        'text-red-dark',
                      (verificationStatus === TalentKycStatus.Submitted ||
                        verificationStatus === TalentKycStatus.Started) &&
                        'text-blue'
                    )}
                  >
                    {VERIFICATION_STATUS_LABEL[verificationStatus]}
                  </span>
                  {')'}
                </span>
              )}
            </h3>
          )}
          <p
            className="text-gray-dark mt-1 whitespace-pre-wrap text-sm font-medium"
            dangerouslySetInnerHTML={{
              __html:
                description ??
                (status === TalentStatus.Verification
                  ? TALENT_STATUS_INFOS_NO_ACCESS?.[
                      TalentStatus.Verification
                    ]?.[verificationStatus]
                  : TALENT_STATUS_INFOS_NO_ACCESS[status]) ??
                '',
            }}
          />
          {status === TalentStatus.Verification &&
            verificationStatus !== TalentKycStatus.Approved &&
            verificationStatus !== TalentKycStatus.Declined &&
            verificationStatus !== TalentKycStatus.Submitted &&
            canAttemptVerification && (
              <DynamicIdentityVerificationDialog
                talentId={talentId}
                verificationStatus={verificationStatus}
              />
            )}
          {status === TalentStatus.Verification && !canAttemptVerification && (
            <p className="text-red-darker mt-4 text-xs">
              You’ve reached the maximum number of identity verification
              attempts, please contact us get more.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export function DashboardBannerManager({
  hasAppAcces,
  status = TalentStatus.Unknown,
  verificationStatus = TalentKycStatus.NotStarted,
}: {
  hasAppAcces: boolean
  status?: TalentStatus
  verificationStatus?: TalentKycStatus
}) {
  const { user } = useAuth()
  const { talentId, canAttemptVerification } = useTalentStatus()
  const Icon = hasAppAcces ? HourglassIcon : TALENT_STATUS_ICONS[status!]
  return (
    <DashboardBanner
      talentId={talentId ?? ''}
      status={status}
      verificationStatus={verificationStatus}
      canAttemptVerification={canAttemptVerification}
      title={
        hasAppAcces
          ? format('Welcome, {firstName}!', user)
          : TALENT_STATUS_BANNER_TITLE_NO_ACCESS[status]
      }
      icon={Icon && <Icon className={'text-blue h-10 w-10'} />}
      subtitle={
        hasAppAcces
          ? 'Congratulations on your progress so far!'
          : TALENT_STATUS_LABELS_NO_ACCESS[status]
      }
      description={
        hasAppAcces
          ? `You now have access to the freelancer app and when a fitting job is on the horizon, we will invite you to the next stage to finalize the process and make you a full member of the UPPER Community. In the meantime, feel free to look around and apply to jobs that catch your eve.`
          : status === TalentStatus.Verification
          ? TALENT_STATUS_INFOS_NO_ACCESS?.[TalentStatus.Verification]?.[
              verificationStatus
            ]
          : TALENT_STATUS_INFOS_NO_ACCESS[status]
      }
    />
  )
}
