import {
  DashboardEngagementsDocument,
  DashboardEngagementsQuery,
  DashboardEngagementsQueryVariables,
  EngagementCardFragment,
  EngagementStatus,
  JobStatus,
  TalentKycStatus,
  TalentStatus,
  TemplatesDocument,
} from '@upper/graphql/freelancer'
import {
  CalendarIcon,
  DocumentIcon,
  InfoIcon,
  LongRightArrowIcon,
  MapIcon,
  RunningIcon,
} from '@upper/icons'
import { SkillTag, Spinner, Tooltip, classNames } from '@upper/ui'
import { formatDate, formatTime } from '@upper/utils'
import Head from 'next/head'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useQuery } from 'urql'
import { DashboardBannerManager } from '../components/dasboard-banner'
import EngagementDetails from '../components/engagement-details'
import JobDetails from '../components/job-details'
import MainLayout from '../components/main-layout'
import { useTalentStatus } from '../components/with-talent-status'
import { ENGAGEMENT_STATUS_LABELS } from '../lib/engagement'

export default function Dashboard() {
  const {
    talentStatus,
    isTalentActive,
    isTalentRejected,
    hasFreelancerAppAccess,
    verificationStatus,
  } = useTalentStatus()
  const [engagementsResult] = useQuery<
    DashboardEngagementsQuery,
    DashboardEngagementsQueryVariables
  >({
    query: DashboardEngagementsDocument,
    pause: isTalentRejected,
    variables: {},
  })
  const [viewJobId, setViewJobId] = React.useState<string | null>(null)
  const router = useRouter()
  const viewEngagementId = router.query.engagementId
    ? String(router.query.engagementId)
    : null

  const engagements = React.useMemo(() => {
    return (
      engagementsResult.data?.engagements?.filter(
        (e) =>
          e.job?.status === JobStatus.InStaffing ||
          [EngagementStatus.Hired, EngagementStatus.Ended].includes(
            e.latestRevision?.status ?? EngagementStatus.Inbox
          )
      ) ?? []
    )
  }, [engagementsResult.data?.engagements])

  if (engagementsResult.fetching) {
    return (
      <div className="flex h-80 items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (engagementsResult.error) {
    return <div>Error: {engagementsResult.error.message}</div>
  }

  return (
    <div>
      <Head>
        <title>Dashboard - Upper Freelancer</title>
        {/* <meta
          http-equiv="content-security-policy"
          content="default-src 'self' *.veriff.me *.veriff.com;
script-src 'self' *.veriff.me *.veriff.com *.hotjar.com *.probity.io;
img-src blob: 'self' *.probity.io;
frame-src 'self' *.hotjar.com;
connect-src 'self' *.veriff.com *.veriff.me *.probity.io;
style-src 'self' *.veriff.com *.veriff.me;"
        /> */}
      </Head>

      {![TalentStatus.Rejected, TalentStatus.Active].includes(
        talentStatus!
      ) && (
        <DashboardBannerManager
          status={talentStatus ?? TalentStatus.Unknown}
          verificationStatus={verificationStatus ?? TalentKycStatus.NotStarted}
          hasAppAcces={hasFreelancerAppAccess ?? false}
        />
      )}
      {/* {[TalentStatus.Applied, TalentStatus.Invited].includes(
            talentStatus!
          ) && <DashboardBanner title={'You are on the waitlist'} />}
          {[TalentStatus.Verification].includes(talentStatus!) && (
            <DashboardBanner title={'Your account is in vetting'} />
          )} */}
      {/* 
      <DashboardBanner
        title={
          <>
            Verify your identity and <br /> get full access to the platform
          </>
        }
      /> */}
      {/* 
      {!isTalentActive && hasFreelancerAppAccess && (
        <DashboardBanner
          title={'Welcome to the freelancer app!'}
          subtitle="Congratulations on your progress so far!"
          icon={<HourglassIcon className="text-blue h-10 w-10" />}
        />
      )} */}

      <div className="max-w-5xl md:mt-8">
        {isTalentRejected ? (
          <div className="bg-red-lightest/40 text-red-darkest rounded-xl p-6">
            <h2 className="text-lg">
              We are sorry you didn’t meet the technical requirements for the
              job therefore you don’t have access to this area. <br />
              We do encourage you to try again in 6 months.
            </h2>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-semibold leading-none">
              Your current jobs and applications
            </h2>
            <div className="mt-4 space-y-5 md:grid md:grid-cols-3 md:gap-5 md:space-y-0">
              {engagements.length === 0 ? (
                <>
                  <EmptyJobCard />
                  <EmptyJobCard
                    hideText
                    className="hidden opacity-60 md:block"
                  />
                  <EmptyJobCard
                    hideText
                    className="hidden opacity-40 md:block"
                  />
                </>
              ) : (
                engagements.map((engagement) => (
                  <EngagementCard
                    key={engagement.id}
                    engagement={engagement}
                    onClick={() => {
                      setViewJobId(engagement.job?.id ?? null)
                    }}
                  />
                ))
              )}
            </div>
          </>
        )}
      </div>

      <JobDetails
        jobId={viewJobId}
        isTalentActive={isTalentActive}
        onClose={() => {
          setViewJobId(null)
        }}
      />

      {viewEngagementId && (
        <EngagementDetails
          engagementId={viewEngagementId}
          isTalentActive={isTalentActive}
          onClose={() => {
            router.replace(
              {
                query: {},
              },
              undefined,
              {
                scroll: false,
                shallow: true,
              }
            )
          }}
        />
      )}
    </div>
  )
}

Dashboard.getLayout = (page: React.ReactElement) => (
  <MainLayout>{page}</MainLayout>
)

Dashboard.authenticate = true

function EngagementCard({
  engagement,
  onClick,
}: {
  engagement: EngagementCardFragment
  onClick: () => void
}) {
  const [templatesResult] = useQuery({
    query: TemplatesDocument,
    variables: {
      filters: {
        groups: [
          'rejection_upper',
          'rejection_fellow',
          'rejection_client',
          'rejection_talent',
        ],
        keys: engagement.latestRevision?.rejectionReasons || null,
      },
    },
    pause: engagement.latestRevision?.rejectionReasons?.length === 0,
  })
  const templates = React.useMemo(() => {
    return templatesResult.data?.templates
  }, [templatesResult])

  const rejected = templates
  const rejectedCommet = engagement?.latestRevision?.statusExtraExplanation

  return (
    <button
      type="button"
      className="flex w-full flex-col text-left"
      onClick={onClick}
    >
      <div className="w-full flex-1 rounded-t-md bg-white px-5 pb-3 pt-5">
        <h3 className="text-2xl font-medium leading-6">
          {engagement.job!.name}
        </h3>
        {engagement.job!.publicPageHeadline && (
          <p className="text-gray-dark mt-1 truncate text-sm">
            {engagement.job!.publicPageHeadline}
          </p>
        )}
        <dl className="mt-9 grid grid-cols-2 gap-x-6 gap-y-8 md:mt-14">
          <div className="relative">
            <dt>
              <RunningIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Start date</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">
              {engagement.job!.startDate
                ? formatDate(engagement.job!.startDate)
                : 'ASAP'}
            </dd>
          </div>
          <div className="relative">
            <dt>
              <CalendarIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Duration</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">{engagement.job!.length}</dd>
          </div>
          <div className="relative">
            <dt>
              <DocumentIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Commitment</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">
              {engagement.job!.commitment}
            </dd>
          </div>
          <div className="relative">
            <dt>
              <MapIcon className="text-gray absolute h-6 w-6" />
              <p className="text-gray ml-8 text-sm">Location</p>
            </dt>
            <dd className="ml-8 mt-0.5 text-sm">{engagement.job!.location}</dd>
          </div>
        </dl>
        <div className="mt-8">
          <ul className="-ml-1 -mt-1 flex flex-wrap">
            {engagement.job!.requiredSkills?.map((skill) => (
              <li key={skill.id} className="ml-1 mt-1">
                <SkillTag>{skill.name}</SkillTag>
              </li>
            ))}
            {engagement.job!.niceToHaveSkills?.map((skill) => (
              <li key={skill.id} className="ml-1 mt-1">
                <SkillTag type="secondary">{skill.name}</SkillTag>
              </li>
            ))}
          </ul>
        </div>
        <p className="mt-4 text-sm">
          <span className="text-gray">Last update:</span>{' '}
          {formatDate(engagement.job!.updatedAt)} |{' '}
          {formatTime(engagement.job!.updatedAt)}
        </p>
      </div>
      <div
        className={classNames(
          'flex w-full items-center justify-between rounded-b-md px-5 py-2.5 text-white',
          engagement.latestRevision!.status === EngagementStatus.Hired &&
            '!bg-green',
          engagement.latestRevision!.status?.includes('Rejected') && 'bg-red',
          'bg-orange'
        )}
      >
        <div className="flex gap-3">
          <span className="text-xs font-bold uppercase">
            {ENGAGEMENT_STATUS_LABELS[engagement.latestRevision!.status!]}
          </span>
          {engagement?.latestRevision?.status?.includes('Rejected') && (
            <Tooltip
              className="!bg-red"
              arrowClassName="text-red"
              content={
                <div className="max-w-xs text-white">
                  {rejected?.map((r) => r.value)?.join(', ')}
                  {rejectedCommet && (
                    <>
                      <br />
                      <span className="opacity-60">{rejectedCommet}</span>
                    </>
                  )}
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          )}
        </div>
        <LongRightArrowIcon className="h-5 w-5" />
      </div>
    </button>
  )
}

function EmptyJobCard({
  hideText = false,
  className,
}: {
  hideText?: boolean
  className?: string
}) {
  const { isTalentActive } = useTalentStatus()

  return (
    <div
      className={classNames(
        'border-gray/50 rounded-md border border-dashed bg-white/30 p-5',
        className
      )}
    >
      <div className={classNames(hideText && 'invisible')}>
        <h3 className="text-xl">&#128577;</h3>
        <p className="text-gray mt-2 text-sm font-medium leading-tight">
          {!isTalentActive && 'You are not engaged with any jobs currently.'}
        </p>
      </div>
      <div className="mt-10 space-y-1">
        <div className="bg-gray-light h-2.5 w-[88px] rounded-md" />
        <div className="bg-gray-light h-2.5 w-[138px] rounded-md" />
        <div className="bg-gray-light h-1.5 w-[68px] rounded-md" />
      </div>
      <div className="mt-12 grid grid-cols-2 gap-2">
        <div className="bg-gray-light/40 h-12 rounded-md" />
        <div className="bg-gray-light/40 h-12 rounded-md" />
      </div>
      <div className="mt-12 space-y-2.5">
        <div className="flex space-x-1">
          <div className="bg-gray-light h-2.5 w-[58px] rounded-md" />
          <div className="bg-gray-light h-2.5 w-[78px] rounded-md" />
          <div className="bg-gray-light h-2.5 w-[68px] rounded-md" />
        </div>
        <div className="bg-gray-light h-2.5 w-[238px] rounded-md" />
      </div>
    </div>
  )
}
